/*BbmTestObj*/
import {EbFactoringGENERATED} from '../_generated/eb-factoring-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {IEbFactoring} from '../_generated/eb-factoring.interface';
import { ICON_LINK } from "../svg/icon-link";


export class EbFactoring extends EbFactoringGENERATED implements IEbFactoring {
//  private _fldActions = null;

  public static ACTION_SHOW_REJECT = 'REJECT';
  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_ACCEPT = 'ACCEPT';
  public static ACTION_SHOW_REVOKE = 'REVOKE';
  public static FACTORING_LEVEL_PARENT = 'PARENT';
  public static FACTORING_LEVEL_CHILD = 'CHILD';
  public static ACTION_CLONE = 'CLONE';
  public static ACTION_DOWNLOAD = 'DOWNLOAD';
  private _fldMyFactoringActions: McHtml | null = null;
  private _fldOutFactoringActions: McHtml | null = null;

  public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  checkedRequestTypeIds!: number [];

  public static override createNewInstance() {
    return new EbFactoring();
  }

  fldMyFactoringActions() {
    if (!this._fldMyFactoringActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.consumer.filter.view-contract')}`, EbFactoring.ACTION_SHOW_PROFILE, this, [McGod.PERM_EB_FACTORING_MY_CONTRACTS_VIEW], '', ActionOptions.OPTION_VIEW);
      if (this.isStatusCdPENDING_ACCEPTANCE()) {
        actSet.addActionB(`<i class="fa mc-submit-color fas fa-check px-1 mc-cursor-pointer"></i> ${this.t('cc.factoring.accept')}`, EbFactoring.ACTION_SHOW_ACCEPT, this, [McGod.PERM_EB_FACTORING_MY_CONTRACTS_VIEW], '', ActionOptions.OPTION_EDIT);
      }
      if (this.isStatusCdPENDING_ACCEPTANCE()) {
        actSet.addActionB(`<i class="fa mc-submit-color fas fa-times px-1 mc-cursor-pointer"></i> ${this.t('cc.factoring.reject')}`, EbFactoring.ACTION_SHOW_REJECT, this, [McGod.PERM_EB_FACTORING_MY_CONTRACTS_VIEW], '', ActionOptions.OPTION_EDIT);
      }
      this._fldMyFactoringActions = html;
    }
    return this._fldMyFactoringActions.items;
  }

  fldOutFactoringActions() {
    if (!this._fldOutFactoringActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.consumer.filter.view-contract')}`, EbFactoring.ACTION_SHOW_PROFILE, this, [McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW], '', ActionOptions.OPTION_VIEW);
      if (this.isStatusCdPENDING_ACCEPTANCE()) {
        actSet.addActionB(`<i class="fas mc-submit-color fa-undo mc-cursor-pointer"></i> ${this.t('cc.factoring.revoke')}`, EbFactoring.ACTION_SHOW_REVOKE, this, [McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW], '', ActionOptions.OPTION_EDIT);
      }
      actSet.addActionB(`<i class="fas mc-submit-color fa-clone mc-cursor-pointer"></i> ${this.t('cc.contracts.clone-contract')}`, EbFactoring.ACTION_CLONE, this, [McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW], '', ActionOptions.OPTION_EDIT);
      this._fldOutFactoringActions = html;
    }
    return this._fldOutFactoringActions.items;
  }

  getStartDate(): string {
    if (this.contractStartDate) {
      return McDateUtils.newFormatDateTimeString(this.contractStartDate);
    }
    return '';
  }


  getEndDate() {
    if (this.contractEndDate) {
      return McDateUtils.newFormatDateTimeString(this.contractEndDate);
    } else {
      return '';
    }
  }

  contractEndDateMethod() {
    if (this.contractEndDate === undefined) {
      return '';
    } else {
     return McDateUtils.newFormatDateString(this.contractEndDate);
    }

  }

  getChildEntityName() {
    return this.childEntity.name;
  }

  contractStartDateMethod() {
    return McDateUtils.newFormatDateString(this.contractStartDate);
  }

  fldStatusCd() {
    if (this.isActive()) {
      return 'Active';
    } else {
      return this.getStatusCdLabel;
    }
  }

  getContractTypeFld() {
    if (this.isTypeCdFACTORING()) {
      return `<div style="display: flex; flex-direction: row;"><img style="margin-right: 10px" src="assets/mc-components-assets/f-blue.svg"> <span>${this.typeCd.toLowerCase()}</span></div>`;
    } else {
      return `<div style="display: flex; flex-direction: row"><img style="margin-right: 10px"  src="assets/mc-components-assets/s-blue.svg"> <span>${this.typeCd.toLowerCase()}</span></div>`;
    }
  }

  isActive(): boolean {
    const today = new Date();
    const contractStartDate = new Date(this.contractStartDate);
    const contractEndDate = new Date(this.contractEndDate);
    return ((this.statusCd === EbFactoring.STATUS_CD_ACCEPTED) && (contractStartDate <= today) && contractEndDate > today);
  }

  displayContractStatus(): string {
    let result = this.statusCd;
    if (this.isActive()) {
      result = 'Active';
    }
    return result;
  }

  getContractNameAndProfileLink() {
    return this.factoringContractName ? `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>${ this.factoringContractName }</div>` : `<div class='icon-text-table-cell' style='cursor: pointer'>${ICON_LINK} <span>-</div>`;
  }
}
